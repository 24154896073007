<script lang="ts">
  import { onMount } from "svelte";

  let data = {
    email: "",
    password: "",
  };

  let emailInputElem: HTMLInputElement;

  let isErrored = false;
  let failedLogin = false;

  async function login() {
    const res = await fetch(`/api/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    if (res?.ok) {
      window.location.href = "/";
      return;
    }

    if (res.status == 401) {
      isErrored = true;
    } else {
      isErrored = false;
      failedLogin = true;
    }
  }

  function msLogin() {
    window.location.href = `/api/oauth/ms/login`;
  }

  onMount(() => {
    emailInputElem.focus();
  });
</script>

<div
  class="flex justify-center h-auto p-8 pl-16 pr-16 items-center flex-col shadow-2xl gap-8"
>
  <img
    src="/static/branding/Altec-logo-kleur.svg"
    class="w-1/2"
    alt="altec logo"
  />

  <img
    src="/static/branding/PrintLabels online_logo.svg"
    class="w-full"
    alt="altec logo"
  />

  <form on:submit|preventDefault={login} class="flex flex-col gap-8 w-full">
    <label class="form-control w-full relative">
      <div class="label top-0 left-0 transform -translate-y-3/4 absolute">
        <span class="label-text text-base-100 text-xs">Email</span>
      </div>
      <input
        class="border-base-100 border-[1px] pl-1 rounded shadow h-8 bg-white {isErrored
          ? 'outline-error outline-2 outline-offset-1 outline'
          : ''}"
        type="email"
        bind:value={data.email}
        bind:this={emailInputElem}
      />
    </label>
    <label class="form-control w-full relative">
      <div class="label top-0 left-0 transform -translate-y-3/4 absolute">
        <span class="label-text text-base-100 text-xs">Password</span>
      </div>
      <input
        class="border-base-100 border-[1px] pl-1 rounded shadow h-8 bg-white {isErrored
          ? 'outline-error outline-2 outline-offset-1 outline'
          : ''}"
        type="password"
        bind:value={data.password}
      />
    </label>
    <button type="submit" class="btn btn-primary w-full">Sign in</button>

    {#if isErrored}
      <p class="text-error text-xs">Wrong email or password</p>
    {:else if failedLogin}
      <p class="text-error text-xs">
        Something went wrong. Please try again later.
      </p>
    {/if}
  </form>

  <div class="w-full flex flex-row items-center">
    <div class="flex-grow h-[1.5px] bg-base-100"></div>
    <p class="text-base-100 text-xs pl-2 pr-2">or</p>
    <div class="flex-grow h-[1.5px] bg-base-100"></div>
  </div>

  <button
    class="flex h-[41px] flex-row items-center bg-white border-[1px] border-[#8C8C8C] pl-[12px] pr-[12px] gap-[12px]"
    on:click={msLogin}
  >
    <img src="/msloginicon.svg" alt="Microsoft logo" />
    <p
      class="font-[15px]"
      style="font-family: 'Segoe UI'; font-weight: 600; color: #5E5E5E"
    >
      Sign in with Microsoft
    </p>
  </button>
</div>
